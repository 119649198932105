<template>
  <div class="download-container">
    <div class="download-item">
      <h3 class="download-title">{{patientApkName}}</h3>
      <img class="download-image" src="../assets/logo.png" alt="患者端图片">
      <p class="download-description">患者/家属请下载此软件对设备进行操控</p>
      <p class="download-description">{{patientApk.uploadTime}}更新</p>
      <el-button type="primary" class="download-btn" @click="downloadPatient">
        <i class="el-icon-download el-icon--left"></i>
        {{patientApk.version}}
      </el-button>
      <a
        :href="patientApk.url"
        :download="patientApk.version"
        target="_blank"
        ref="downloadLink"
        style="display: none;"
        />
      <!-- <el-button class="download-btn" type="primary" @click="downloadPatient">下载 {{patientApk.version}}</el-button> -->
    </div>
    <div class="download-item">
      <h3 class="download-title">{{doctorApkName}}</h3>
      <img class="download-image" src="../assets/logo.png" alt="医生端图片">
      <p class="download-description">医生/企业用户请下载此软件管理患者数据</p>
      <p class="download-description">{{doctorApk.uploadTime}}更新</p>
      <el-button type="primary" class="download-btn" @click="downloadDoctor">
        <i class="el-icon-download el-icon--left"></i>
        {{doctorApk.version}}
      </el-button>
      <!-- <el-button class="download-btn" type="primary" @click="downloadDoctor">下载 {{doctorApk.version}}</el-button> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'DownloadPage',
  data() {
    return {
      patientApkName: '爱勇者（患者端）',
      doctorApkName: '爱勇者（医生和管理端）',
      patientApk: {
        deviceType: '爱勇者（患者端）',
        version: '',
        url: '',
        uploadTime: ''
      },
      doctorApk: {
        deviceType: '爱勇者（医生和管理端）',
        version: '',
        url: '',
        uploadTime: ''
      }
    }
  },
  mounted() {
    this.getDownloadData()
  },
  methods: {
    getDownloadData() {
      this.$http({
        url: this.$http.adornUrl('firmware/getNewAppDownloadInfo'),
        method: 'get',
        data: this.$http.adornData({})
      }).then(({data}) => {
        this.patientApk = data[0]
        this.doctorApk = data[1]
      })
    },
    downloadPatient() {
      if (this.patientApk.url != '') {
        this.downloadFile(this.patientApk.url, this.patientApkName + '_' + this.patientApk.version)
      }
      // 这里处理患者端的下载逻辑，例如使用window.location或a标签模拟下载
    },
    downloadDoctor() {
      if (this.doctorApk.url != '') {
        this.downloadFile(this.doctorApk.url, this.doctorApkName + '_' + this.doctorApk.version)
      }
      // 这里处理医生端的下载逻辑
    },
    downloadFile(url, name) {
      const downloadLink = this.$refs.downloadLink;
      downloadLink.href = url;
      downloadLink.download = name;
      // 触发点击
      document.body.appendChild(downloadLink); // 必须添加到dom中
      downloadLink.click(); // 模拟点击
      document.body.removeChild(downloadLink);
    }
  }
}
</script>

<style scoped>
.download-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  max-width: 100%; /* 或者你想要的宽度 */
}

.download-item {
  margin-bottom: 20px; /* 控制下载项之间的间距 */
  text-align: center; /* 如果需要的话，让内容居中 */
}

.download-title {
  margin-bottom: 10px; /* 软件名称与图片之间的间距 */
}

.download-image {
  width: 100px; /* 或者你想要的宽度 */
  height: 100px; /* 或者你想要的高度 */
  margin-bottom: 10px; /* 图片与描述之间的间距 */
  object-fit: cover; /* 保持图片比例 */
}

.download-description {
  margin-bottom: 20px; /* 描述与下载按钮之间的间距 */
}

.download-btn {
  padding-left: 20px; /* 为图标预留空间 */
  width: 100%; /* 让下载按钮占满整行 */
  padding: 15px 0; /* 调整按钮的内外边距 */
  font-size: 18px; /* 调整按钮字体大小 */
} 

.el-icon--left {
  margin-right: 5px; /* 图标和文本之间的间距 */
}
</style>